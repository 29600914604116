<template>
    <svg
        class="text-white stroke-current w-5 h-auto max-h-5 flex-shrink-0"
        width="30"
        height="25"
        viewBox="0 0 30 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 24H29M17 24V2C17 1.73478 16.8946 1.48043 16.7071 1.29289C16.5196 1.10536 16.2652 1 16 1H4C3.73478 1 3.48043 1.10536 3.29289 1.29289C3.10536 1.48043 3 1.73478 3 2V24M27 24V10C27 9.73478 26.8946 9.48043 26.7071 9.29289C26.5196 9.10536 26.2652 9 26 9H17M7 6H11M9 14H13M7 19H11M21 19H23M21 14H23"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
